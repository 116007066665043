function coreYearCopy() {
    let el = $('.js-year-copy:not(.js-year-copy-enabled)');

    if (el.length > 0) {
        let date        = new Date();
        let curYear     = date.getFullYear();
        let baseYear    = (el.html().length > 0) ? el.html() : curYear;

        // Add .js-scroll-to-enabled class to tag it as activated and set the correct year
        el.addClass('js-year-copy-enabled').html(
            (parseInt(baseYear) >= curYear) ? curYear : baseYear + '-' + curYear.toString().substr(2, 2)
        );
    }
}

$(() => {
    coreYearCopy();
});